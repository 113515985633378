import React, { Fragment } from "react";
import PropTypes from 'prop-types';
import Sticky from "react-stickynode";
import { graphql } from "gatsby"
import { ThemeProvider } from "styled-components";

import { theme } from "../common/theme";
import { ResetCSS } from "../assets/css/style";
import { ContentWrapper, GlobalStyle } from "../assets/css/saas.style";
import {  Navbar } from "../containers/common";
import { DrawerProvider } from "../contexts/DrawerContext";
import SEO from "../components/SEO";
import { JobSection } from "../containers/jobs";
import Footer from '../containers/common/Footer';

const browser = typeof window !== "undefined" && window;

const JobsPage = ({ location, data }) => {
  const jobList = React.useMemo(() => {
    return data.allAirtable.nodes.map((node) => ({
      ...node.data,
      slug: `/${node.data.location[0].toLowerCase()}/${node.data.date}/${node.data.title.toLowerCase().replace(/[/+ #]/ig, '-')}-${node.data.record_id.substr(node.data.record_id.length - 6)}`,
      company: {
        ...node.data.company[0].data
      }
    }));
  }, [data]);

  return browser && (
    <ThemeProvider theme={theme}>
      <Fragment>
        <SEO title="Latest tech jobs" />
        <ResetCSS />
        <GlobalStyle />
        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar notMainPage />
            </DrawerProvider>
          </Sticky>
          <JobSection url={location.href} jobList={jobList} searchData={data.localSearchJobs} />
          <Footer notMainPage />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};

JobsPage.propTypes = {
  location: PropTypes.object,
  data: PropTypes.object
};

export default JobsPage;

export const pageQuery = graphql`
  query {
    localSearchJobs {
      index
      store
    }
    allAirtable(
      filter: {table: {eq: "Positions 📌"}}
      sort: {order: [DESC, DESC], fields: [data___status, data___date]}
    ) {
      nodes {
        data {
          record_id
          location
          company {
            data {
              name
              logo {
                localFiles {
                  childImageSharp {
                    gatsbyImageData(width: 100)
                  }
                }
              }
              brand_consent
            }
          }
          title
          telecomutting
          status
          position
          keywords
          experience
          employment
          date
        }
      }
    }
  }
`
